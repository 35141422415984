import { useRelayMutation } from '@/lib';
import {
  CreateOrderFromCheckoutDocument,
  CreateOrderFromCheckoutMutation,
  CreateOrderFromCheckoutMutationVariables,
  ShopOrderDetailsFragment,
  ShopOrderFragment,
  ShopShowsQuery,
  useShopOrderQuery,
  useShopOrdersQuery,
  ViewerCartItemsByShopSlugDocument,
  ViewerCartItemsByShopSlugQuery,
  ViewerCartItemsByShopSlugQueryVariables,
  ViewerCheckoutsByShopSlugDocument,
  ViewerCheckoutsByShopSlugQuery,
  ViewerCheckoutsByShopSlugQueryVariables,
  FulfillOrderShipmentLineItemMutation,
  FulfillOrderShipmentLineItemMutationVariables,
  FulfillOrderShipmentLineItemDocument,
  OrderShipmentLineItem,
  OrderShipmentLineItemFragmentDoc,
  OrderShipment,
  OrderShipmentLineItemFragment,
  ShopOrderFragmentDoc,
  UnfulfillOrderShipmentLineItemMutation,
  UnfulfillOrderShipmentLineItemMutationVariables,
  UnfulfillOrderShipmentLineItemDocument,
  CancelOrderMutation,
  CancelOrderMutationVariables,
  CancelOrderDocument,
  SearchShopOrdersByShopQuery,
  useSearchShopOrdersByShopQuery,
  CreateOrderFromCheckoutPosMutation,
  CreateOrderFromCheckoutPosMutationVariables,
  CreateOrderFromCheckoutPosDocument,
  useOrderQuery,
  MarkShopOrderAsPickedMutation,
  MarkShopOrderAsPickedMutationVariables,
  MarkShopOrderAsPickedDocument,
} from '@/api';
import { QueryHookOptions, useApolloClient } from '@apollo/client';
import { useCallback, useState } from 'react';

export function useCreateOrderFromCheckout() {
  return useRelayMutation<
    CreateOrderFromCheckoutMutation,
    CreateOrderFromCheckoutMutationVariables,
    'createOrderFromCheckout'
  >(CreateOrderFromCheckoutDocument, 'createOrderFromCheckout', {
    update(cache, { data }) {
      if (data?.createOrderFromCheckout?.order) {
        for (const shopOrder of data?.createOrderFromCheckout?.order?.shopOrders?.nodes ?? []) {
          // Clear all cart items for the shop
          cache.writeQuery<ViewerCartItemsByShopSlugQuery, ViewerCartItemsByShopSlugQueryVariables>({
            query: ViewerCartItemsByShopSlugDocument,
            variables: {
              shopSlug: shopOrder?.shop?.slug!
            },
            data: {
              __typename: 'Query',
              viewerCartItemsByShopSlug: {
                __typename: 'ViewerCartItemsByShopSlugConnection',
                nodes: [],
                pageInfo: {
                  __typename: 'PageInfo',
                  hasNextPage: false,
                  hasPreviousPage: false,
                  startCursor: null,
                  endCursor: null
                }
              }
            }
          });

          // Clear all the checkouts for the shop
          cache.writeQuery<ViewerCheckoutsByShopSlugQuery, ViewerCheckoutsByShopSlugQueryVariables>({
            query: ViewerCheckoutsByShopSlugDocument,
            variables: {
              shopSlug: shopOrder?.shop?.slug!
            },
            data: {
              __typename: 'Query',
              viewerCheckoutsByShopSlug: {
                __typename: 'ViewerCheckoutsByShopSlugConnection',
                edges: [],
                pageInfo: {
                  __typename: 'PageInfo',
                  hasNextPage: false,
                  hasPreviousPage: false,
                  startCursor: null,
                  endCursor: null
                }
              }
            }
          });
        }
      }
    }
  });
}

export function useCreateOrderFromCheckoutPos() {
  return useRelayMutation<
    CreateOrderFromCheckoutPosMutation,
    CreateOrderFromCheckoutPosMutationVariables,
    'createOrderFromCheckoutPos'
  >(CreateOrderFromCheckoutPosDocument, 'createOrderFromCheckoutPos', {
    update(cache, { data }) {
      if (data?.createOrderFromCheckoutPos?.order) {
        for (const shopOrder of data?.createOrderFromCheckoutPos?.order?.shopOrders?.nodes ?? []) {
          // Clear all cart items for the shop
          cache.writeQuery<ViewerCartItemsByShopSlugQuery, ViewerCartItemsByShopSlugQueryVariables>({
            query: ViewerCartItemsByShopSlugDocument,
            variables: {
              shopSlug: shopOrder?.shop?.slug!
            },
            data: {
              __typename: 'Query',
              viewerCartItemsByShopSlug: {
                __typename: 'ViewerCartItemsByShopSlugConnection',
                nodes: [],
                pageInfo: {
                  __typename: 'PageInfo',
                  hasNextPage: false,
                  hasPreviousPage: false,
                  startCursor: null,
                  endCursor: null
                }
              }
            }
          });

          // Clear all the checkouts for the shop
          cache.writeQuery<ViewerCheckoutsByShopSlugQuery, ViewerCheckoutsByShopSlugQueryVariables>({
            query: ViewerCheckoutsByShopSlugDocument,
            variables: {
              shopSlug: shopOrder?.shop?.slug!
            },
            data: {
              __typename: 'Query',
              viewerCheckoutsByShopSlug: {
                __typename: 'ViewerCheckoutsByShopSlugConnection',
                edges: [],
                pageInfo: {
                  __typename: 'PageInfo',
                  hasNextPage: false,
                  hasPreviousPage: false,
                  startCursor: null,
                  endCursor: null
                }
              }
            }
          });
        }
      }
    }
  });
}

export function getOrderById(orderId: string) {
  const {data, loading, error, ...rest} = useOrderQuery({
    variables: {
      orderId: orderId
    }
  })

  return {data, loading, error, ...rest}
};

export function useShopOrders(shopId: string, options?: QueryHookOptions<ShopShowsQuery, any>) {
  const {
    data,
    fetchMore: fetchMoreFn,
    ...rest
  } = useShopOrdersQuery({
    variables: {
      shopId
    },
    ...options
  });
  const [fetchingMoreShopOrders, setFetchingMoreShopOrders] = useState<boolean>(false);

  const fetchMoreShopOrders = useCallback(async () => {
    setFetchingMoreShopOrders(true);
    try {
      await fetchMoreFn({
        variables: {
          cursor: data?.shopOrdersByShop?.pageInfo?.endCursor
        }
      });
    } finally {
      setFetchingMoreShopOrders(false);
    }
  }, [data, fetchMoreFn, setFetchingMoreShopOrders]);

  return {
    shopOrders: (data?.shopOrdersByShop?.edges?.map((edge) => edge.node) ?? []) as ShopOrderFragment[],
    hasMoreShopOrders: data?.shopOrdersByShop?.pageInfo?.hasNextPage ?? false,
    fetchingMoreShopOrders,
    fetchMoreShopOrders,
    ...rest
  };
}

export function useShopOrder(id: string, skip?: boolean) {
  const { data, ...rest } = useShopOrderQuery({
    variables: {
      id
    },
    skip
  });

  const shopOrder = (data?.shopOrder as ShopOrderDetailsFragment | null) ?? null;
  const referenceNumber = data?.shopOrder?.referenceNumber ?? '';

  return { shopOrder, referenceNumber, ...rest };}

  export function useOrder(orderId: string, skip?: boolean) {
    const { data, refetch: refetchQuery, ...rest } = useOrderQuery({
      variables: {
        orderId
      },
      skip
    });
  
    const refetch = (newOrderId?: string) => {
      refetchQuery({
        orderId: newOrderId ?? orderId 
      });
    };
  
    return {
      order: data?.order ?? null,
      refetch,
      ...rest
    };
  }
  
export function useFulfillOrderShipmentLineItem() {
  const client = useApolloClient();

  return useRelayMutation<
    FulfillOrderShipmentLineItemMutation,
    FulfillOrderShipmentLineItemMutationVariables,
    'fulfillOrderShipmentLineItem'
  >(FulfillOrderShipmentLineItemDocument, 'fulfillOrderShipmentLineItem', {
    optimisticResponse({ input }) {
      const orderShipmentLineItem = client.readFragment<OrderShipmentLineItemFragment>({
        fragment: OrderShipmentLineItemFragmentDoc,
        fragmentName: 'OrderShipmentLineItem',
        id: input.orderShipmentLineItemId
      });

      const shopOrder = client.readFragment<ShopOrderFragment>({
        fragment: ShopOrderFragmentDoc,
        fragmentName: 'ShopOrder',
        id: orderShipmentLineItem?.orderShipment?.shopOrderId
      });

      return {
        __typename: 'Mutation',
        fulfillOrderShipmentLineItem: {
          __typename: 'FulfillOrderShipmentLineItemPayload',
          orderShipmentLineItem: {
            ...orderShipmentLineItem!,
            fulfilledQuantity: orderShipmentLineItem!.totalQuantity,
            orderShipment: {
              ...orderShipmentLineItem?.orderShipment!,
              __typename: 'OrderShipment',
              shopOrder: shopOrder!
            }
          },
          errors: null
        }
      };
    }
  });
}

export function useUnfulfillOrderShipmentLineItem() {
  const client = useApolloClient();

  return useRelayMutation<
    UnfulfillOrderShipmentLineItemMutation,
    UnfulfillOrderShipmentLineItemMutationVariables,
    'unfulfillOrderShipmentLineItem'
  >(UnfulfillOrderShipmentLineItemDocument, 'unfulfillOrderShipmentLineItem', {
    optimisticResponse({ input }) {
      const orderShipmentLineItem = client.readFragment<OrderShipmentLineItemFragment>({
        fragment: OrderShipmentLineItemFragmentDoc,
        fragmentName: 'OrderShipmentLineItem',
        id: input.orderShipmentLineItemId
      });

      const shopOrder = client.readFragment<ShopOrderFragment>({
        fragment: ShopOrderFragmentDoc,
        fragmentName: 'ShopOrder',
        id: orderShipmentLineItem?.orderShipment?.shopOrderId
      });

      return {
        __typename: 'Mutation',
        unfulfillOrderShipmentLineItem: {
          __typename: 'UnfulfillOrderShipmentLineItemPayload',
          orderShipmentLineItem: {
            ...orderShipmentLineItem!,
            fulfilledQuantity: orderShipmentLineItem!.totalQuantity,
            orderShipment: {
              ...orderShipmentLineItem?.orderShipment!,
              __typename: 'OrderShipment',
              shopOrder: shopOrder!
            }
          },
          errors: null
        }
      };
    }
  });
}

export function useCancelOrder() {
  return useRelayMutation<CancelOrderMutation, CancelOrderMutationVariables, 'cancelOrder'>(
    CancelOrderDocument,
    'cancelOrder'
  );
}

export function useMarkShopOrderAsPicked() {
  return useRelayMutation<MarkShopOrderAsPickedMutation, MarkShopOrderAsPickedMutationVariables, 'markShopOrderAsPicked'>(
    MarkShopOrderAsPickedDocument,
    'markShopOrderAsPicked'
  );
}

export function useSearchShopOrdersByShop(
  shopId: string,
  query: string,
  options?: QueryHookOptions<SearchShopOrdersByShopQuery, any>
) {
  const {
    data,
    fetchMore: fetchMoreFn,
    ...rest
  } = useSearchShopOrdersByShopQuery({
    fetchPolicy: 'network-only',
    variables: {
      shopId,
      query
    },
    ...options
  });
  const [fetchingMoreShopOrderResults, setFetchingMoreShopOrderResults] = useState<boolean>(false);

  const fetchMoreShopOrderResults = useCallback(async () => {
    setFetchingMoreShopOrderResults(true);
    try {
      await fetchMoreFn({
        variables: {
          cursor: data?.searchShopOrdersByShop?.pageInfo.endCursor
        }
      });
    } finally {
      setFetchingMoreShopOrderResults(false);
    }
  }, [data, setFetchingMoreShopOrderResults]);

  return {
    shopOrderResults: (data?.searchShopOrdersByShop?.edges?.map((edge) => edge.node) ?? []) as ShopOrderFragment[],
    hasMoreShopOrderResults: data?.searchShopOrdersByShop?.pageInfo.hasNextPage ?? false,
    fetchingMoreShopOrderResults,
    fetchMoreShopOrderResults,
    ...rest
  };
}


